import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Image } from "../components/Core"
import { Section, Container } from "../components/Layouts"
import "../css/theme/ssrp.scss"
import Seamless from "../components/SeamlessEmbed"
import TheaterVideo from "../components/Theater/TheaterVideo"

function SSRPPage() {
  return (
    <Layout navSpacer language="en">
      <SEO
        title="SSRP Trusted Partner - Nuvolum - Seeds Scientific Research & Performance"
        description="Seeds Scientific Research & Performance trusted partner, Nuvolum."
      />

      <Section zeroTop>
        <Container className="pb-2">
          <div className="nv">
            <div className="nv__section">
              <div className="nv__container">
                <div className="nv__split" style={{ alignItems: "center" }}>
                  <div>
                    <TheaterVideo
                      videoUrl="https://youtu.be/UXko6dWcO_8"
                      language="en"
                      playing
                      controls
                      asWrapper
                      wrapperClassName="nv__play">
                      <Image
                        useAR={false}
                        publicId="NUVO/V4/ssrp-preferred-partner-dr-seeds-thumbnail-v2"
                      />
                    </TheaterVideo>
                  </div>
                  <h1>Cellular Medicine’s Premiere Marketing Experts</h1>
                </div>
              </div>
            </div>
            <div className="nv__section">
              <div className="nv__container">
                <div className="nv__line"></div>
              </div>
            </div>
            <div className="nv__section">
              <div className="nv__container">
                <p className="nv__subheading">SSRP Exclusive Offer:</p>

                <div className="nv__split reverse">
                  <div>
                    <h2>Complimentary Marketing Analysis Valued at $15,000</h2>
                    <p
                      style={{
                        color: "#b6b6b6",
                        fontSize: "30px",
                        fontWeight: "bold",
                        margin: "10px 0px"
                      }}>
                      Extended for February
                    </p>
                    <p>
                      Discover untapped opportunities and learn how Nuvolum can
                      help you achieve your goals.
                    </p>
                  </div>
                  <div className="nv__quote">
                    <div>
                      <Image
                        useAR={false}
                        publicId="NUVO/V4/quote-icon"
                        className="nv__quote-icon mb-1"
                      />
                      <p>
                        They’ve completely changed the way I view marketing, and
                        I’m beyond impressed with their creativity, dedication,
                        and ability to deliver results.
                      </p>
                      <h4 className="mt-1">
                        Madison Lepore, MSN, APRN{" "}
                        <span>REDOX Medical Group</span>
                      </h4>
                    </div>
                    <div>
                      <Image
                        useAR={false}
                        className="nv__quote-image"
                        publicId="NUVO/V4/ssrp-preferred-partner-madison-quote"
                      />
                    </div>
                  </div>
                </div>
                <div className="nv__line"></div>
              </div>
            </div>
            <div className="nv__section">
              <div className="nv__container">
                <p className="nv__subheading">What Nuvolum Does for You:</p>

                <h3 style={{ textAlign: "center" }}>
                  Nuvolum provides the services you need to attract new
                  patients, build your reputation, and focus on what matters
                  most—delivering exceptional care.
                </h3>
                <div className="nv__grid-3x3">
                  <div className="nv__card">Strategy Development</div>
                  <div className="nv__card">Video Production</div>
                  <div className="nv__card">Media Training</div>
                  <div className="nv__card">Research &amp; Data Science</div>
                  <div className="nv__card">SEO</div>
                  <div className="nv__card">Ai Integration</div>
                  <div className="nv__card">Brand Development</div>
                  <div className="nv__card">Public Relations</div>
                  <div className="nv__card">Podcast</div>
                  {/* <div className="nv__card">Data Science</div>
                  <div className="nv__card">Creative</div>
                  <div className="nv__card">Technology & Search</div>
                  <div className="nv__card">Digital Marketing</div>
                  <div className="nv__card">Business Strategy</div>
                  <div className="nv__card">Public Relations</div>
                  <div className="nv__card">Video Production</div>*/}
                </div>
              </div>
            </div>
            <div className="nv__section">
              <div className="nv__container">
                <div className="nv__line"></div>
              </div>
            </div>
            <div className="nv__section mb-0">
              <div className="nv__container mb-0">
                <div className="nv__form">
                  <h3>
                    Request your complimentary Marketing Analysis today by
                    completing the form below!
                  </h3>
                  <Seamless src="https://secureform.seamlessdocs.com/f/7598ea6d8ac94794b054cc26d8e5fa36?embedded=true" />
                  {/* <script>
              window.seamlessVariables = {
                url: "https://secureform.seamlessdocs.com",
              };
            </script>
<script type="text/javascript" src="https://secureform.seamlessdocs.com/components/odoForms/js/embed-iframe/head.js" ></script>
<iframe className="seamlessdocsEmbededIframe" src="https://secureform.seamlessdocs.com/f/7598ea6d8ac94794b054cc26d8e5fa36?embedded=true" width="100%" height="700px" frameborder="0" sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox"></iframe>
<script type="text/javascript" src="https://secureform.seamlessdocs.com/components/odoForms/js/embed-iframe/body.js" ></script> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default SSRPPage
